/* sidenav.css */

.sidenav {
  width: 100%;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  font-family: 'Roboto', sans-serif; /* Example font */
}

.brand {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f0f2f5;
}

.brand-logo {
  width: 80px;
  height: auto;
  margin-right: 4px;
}

.brand-title {
  font-size: 18px;
  font-weight: 500; /* Semi-bold */
  color: #1b60e9; /* Dark color for contrast */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.new-chat-container {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.new-chat-button {
  width: 100%;
  max-width: 200px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidenav-menu {
  flex: 1;
  overflow-y: auto;
  font-size: 14px;
}

.chat-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400; /* Regular font weight */
}

.chat-link {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-chat-button {
  margin-left: 8px;
  font-size: 12px; /* Smaller font size for icons */
  color: #ff4d4f; /* Red color for delete icon */
}

@media (max-width: 768px) {
  .brand-title {
    font-size: 16px;
  }
  .brand-logo {
    display: none;
  }
  .new-chat-button {
    max-width: 100px;
  }
}
