/* src/components/auth/Auth.css */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.auth-card {
  width: 300px;
}

.auth-form {
  max-width: 300px;
}

.auth-form-button {
  width: 100%;
}

.auth-links {
  margin-top: 15px;
  text-align: center;
}

.google-button {
  background-color: #4285F4;
  color: white;
  border: none;
}

.google-button:hover {
  background-color: #357ae8;
  color: white;
}