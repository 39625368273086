/* settings.css */

.settings-dropdown {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-radius-section,
.vector-store-section {
  margin-bottom: 20px;
}
.vector-store-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Adjust the gap value as needed */
}


.vector-store-section {
  margin-bottom: 20px;
}
.search-radius-section h4,
.vector-store-section h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.search-radius-slider {
  margin-bottom: 10px;
}

.search-radius-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.ant-slider-track {
  background-color: #4CAF50;
}

.ant-slider-handle {
  border-color: #4CAF50;
}

.ant-select {
  width: 100%;
}

.ant-select-selector {
  background-color: #fff !important;
  border-color: #ddd !important;
}

.ant-select-selection-item {
  color: #333;
}

.ant-btn {
  background-color: #f0f0f0;
  border-color: #ddd;
  color: #333;
}

.ant-btn:hover {
  background-color: #e0e0e0;
  border-color: #ccc;
}

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
}

.rotated-button {
  transform: rotate(90deg);
  position: fixed;
  right: -14px;
  top: 50%;
  z-index: 1000;
}

/* Override Ant Design's default styles for light theme */
.ant-dropdown-menu {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item:hover {
  background-color: #e0e0e0;
}

.ant-tooltip-inner {
  background-color: #333;
  color: #fff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .settings-dropdown {
    width: 90vw;
    max-width: 300px;
  }
}