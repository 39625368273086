/* Existing styles */
.site-header {
  background-color: transparent !important;
}

.ant-header-fixed {
  background-color: transparent !important;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header-left {
  display: flex;
  align-items: center;
}

.breadcrumb {
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center;
  height: 100%;
}

.menu-button {
  background: transparent;
  border: none;
  transition: all 0.3s ease;
  padding: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.menu-button .anticon {
  font-size: 24px;
}

.auth-button {
  min-width: 100px;
}

.profile-avatar {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: aqua;
  color: #ffffff;
  cursor: pointer;
}

.ant-dropdown-menu-item {
  padding: 8px 16px;
  min-width: 130px;
}

.ant-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item .anticon {
  margin-right: 8px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .menu-button .anticon {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .menu-button .anticon {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .menu-button .anticon {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .menu-button .anticon {
    font-size: 16px;
  }
}

/* Additional styles for Cognito integration (if needed) */
/* For example, if you need to style the Cognito-specific elements */
.cognito-form {
  /* Add styles for Cognito forms if necessary */
}

.cognito-button {
  /* Add styles for Cognito-specific buttons if necessary */
}