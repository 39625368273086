/* Base styles for the chat main area */
.chat-main-area {
    display: flex;
    flex-direction: column;
  height: 75vh; /* Set a fixed height for the chat area */  
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Light background for chat area */
    overflow-y: auto;
}

.chat-message-area {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #fff; /* White background for message area */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.message-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.message-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px; /* Reduce space between messages */
}
  
.message-item.user {
  justify-content: flex-end;
}

.message-item.ai {
  justify-content: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 8px 12px; /* Reduce padding for a more compact look */
  border: grey 1px solid; /* Light blue border for user messages */
  border-radius: 12px; /* Adjust border radius for smaller bubbles */
    word-wrap: break-word; /* Ensure words break correctly */
    word-break: break-word; /* Ensure words break within long words */
  white-space: normal; /* Preserve whitespace and wrap text */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Light shadow for message bubbles */
}

.message-item.user .message-content {
  background-color: #e6f7ff; /* Light blue background for user messages */
  align-self: flex-end;
}

.message-item.ai .message-content {
  background-color: #f1f1f1; /* Light gray background for bot messages */
  align-self: flex-start;
  margin-left: 10px;
}

.copy-button {
  margin-left: 6px; /* Reduce space next to the copy button */
  align-self: flex-start; /* Align the button to the top */
}

.chat-input-area {
  display: flex;
  align-items: center;
  gap: 8px; /* Reduce gap between input and button */
  padding: 8px;
  border-top: 1px solid #e8e8e8;
  background-color: #fff; /* White background for input area */
  border-radius: 10px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

/* Checkbox */
.ant-checkbox {
  top: 0;
  left: 0;

}


/* Checkbox inner square */
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 2px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

/* Checkbox when checked */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

/* Checkmark */
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}



/* Selected message item */
.message-item.selected {
  background-color: rgba(24, 144, 255, 0.05);
}

/* Adjust the positioning of the checkbox within the message item */
.message-item .ant-checkbox-wrapper {
  align-self: flex-start;
  margin-top: 3px;
}

.chat-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.settings-button {
  background: none;
  border: none;
  padding: 0;
  font-size: 18px;
  cursor: pointer;
  }
  
  @media (max-width: 1200px) {
  .chat-main-area {
    padding: 8px;
  }

  .chat-input-area {
    flex-direction: column;
    gap: 8px;
    }
  }
  
  @media (max-width: 768px) {
  .chat-main-area {
    padding: 6px;
  }

  .chat-input-area {
    flex-direction: column;
    gap: 6px;
    }
  }
  
  @media (max-width: 576px) {
  .chat-main-area {
    padding: 4px;
  }

  .chat-input-area {
    flex-direction: column;
    gap: 4px;
    }
  }
  