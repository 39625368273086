/* Styles for the main layout */
.layout-dashboard {
  display: flex;
}

/* Styles for the Drawer component */
.ant-drawer-body {
  padding: 0;
}

/* Styles for the Sidenav component */
.ant-menu-item,
.ant-menu-submenu-title {
  white-space: normal;
  height: auto;
  line-height: 1.5;
  padding: 12px 16px;
}

.ant-menu-item-icon,
.ant-menu-submenu-arrow {
  vertical-align: top;
  margin-top: 4px;
}

.ant-menu-title-content {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: calc(100% - 24px); /* Adjust based on your icon width */
}

/* Ensure content doesn't overflow */
.site-content {
  overflow-x: hidden;
}

/* Center alignment styles */
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the container takes full height */
}

.text-center {
  text-align: center;
}

/* Utility class for flexbox centering */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 8px 12px;
  }
}