.doc-page {
  background-color: #ffffff;
  color: #000000;
  height: 82vh;
  margin: 20px;
  border-radius: 10px;
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.doc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.doc-tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.doc-tabs {
  display: flex;
}

.doc-tab-btn {
  margin-right: 12px;
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  color: #000000;
  border-radius: 6px;
}

.doc-tab-btn-active {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #ffffff;
}

.doc-sync-btn {
  background-color: #1890ff;
  border-color: #1890ff;
  border-radius: 6px;
  color: #ffffff;
}

.doc-content {
  display: flex;
  gap: 24px;
  flex: 1;
  overflow: hidden;
}

.doc-list-view {
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 16px;
  overflow-y: auto;

}

.doc-details-panel {
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 24px;
  overflow-y: auto;
}

.doc-list-item {
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 8px;
}

.doc-list-item:hover, .doc-list-item-selected {
  background-color: #f5f5f5;
}

.doc-item-title {
  color: #000000;
  font-weight: 500;
}

.doc-item-description, .doc-item-date {
  color: #8c8c8c;
  font-size: 0.9em;
}

.doc-divider {
  background-color: #f0f0f0;
  margin: 16px 0;
}

.doc-panel-title, .doc-section-title {
  color: #000000;
  margin-bottom: 16px;
}

.doc-status-ready {
  color: #52c41a;
}

.doc-edit-icon {
  color: #1890ff;
  cursor: pointer;
}

.doc-table {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  margin-bottom: 24px;
}

.doc-table th {
  background-color: #fafafa;
  color: #000000;
}

.doc-table td {
  background-color: #ffffff;
  color: #000000;
}

.doc-load-more-btn {
  color: #1890ff;
  margin-top: 16px;
}

.doc-load-more-btn:hover, .doc-load-more-btn:focus {
  color: #40a9ff;
}

.doc-detail-item {
  margin-bottom: 16px;
}

/* Custom scrollbar styles */
.doc-list-view::-webkit-scrollbar,
.doc-details-panel::-webkit-scrollbar {
  width: 6px;
}

.doc-list-view::-webkit-scrollbar-thumb,
.doc-details-panel::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3px;
}

.doc-list-view::-webkit-scrollbar-track,
.doc-details-panel::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}