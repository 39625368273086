.context-sider {
    flex: 0 0 10vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: #fff; /* Matching the background color of PDF viewer */
    border-left: 1px solid #e8e8e8;
    margin: 0 10px; /* Add margin to create horizontal gaps */
    border-radius: 10px; /* Rounded corners for context sider */
    border: 1px solid #d9d9d9; /* Add border for better visibility */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .pdf-nav-button {
    white-space: normal; /* Allow text to wrap */
    flex-grow: 1;
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis to truncated text */
    width: 100%; /* Ensure the button takes the full width of its container */
    text-align: left; /* Align text to the left */
    word-wrap: break-word; /* Ensure words break correctly */
    word-break: break-word; /* Ensure words break within long words */
    display: block; /* Ensure the button is treated as a block element */
    margin-bottom: 10px; /* Add some space between buttons */
    border-radius: 10px; /* Add border radius to the buttons */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    height: auto; /* Allow the button to adjust its height automatically */
    min-height: 40px; /* Ensure a minimum height for the buttons */
  }
  
  @media (max-width: 1200px) {
    .context-sider {
      flex: 0 0 15vw; /* Adjust sider width for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .context-sider {
      flex: 0 0 20vw; /* Adjust sider width for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .context-sider {
      flex: 0 0 30vw; /* Adjust sider width for smaller screens */
    }
  
    .pdf-nav-button {
      padding: 8px; /* Adjust padding for smaller screens */
      min-height: 30px; /* Adjust minimum height for smaller screens */
    }
  }
  