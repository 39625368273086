.footer {
  background: transparent;
  padding: 16px 24px;
  transition: all 0.3s ease;
}

.footer-content {
  font-size: 14px;
  color: #888;
  transition: color 0.3s ease;
}

.company-name {
  font-weight: bold;
  color: #1890ff;
  margin-left: 5px;
}

.social-icons {
  display: flex;
}

.social-icon {
  font-size: 20px;
  color: #888;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #1890ff;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .footer {
    padding: 12px 16px;
  }

  .footer-content {
    font-size: 12px;
  }

  .social-icon {
    font-size: 18px;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .footer {
    background: rgba(0, 0, 0, 0.1);
  }

  .footer-content, .social-icon {
    color: #bfbfbf;
  }

  .company-name, .social-icon:hover {
    color: #40a9ff;
  }
}