.content-container {
  display: flex;
  width: 100%;
  height: 87vh;
  gap: 10px; /* Gap between sections */
  padding: 20px;

}

.chat-container {
  flex: 1 1 45vw; /* Adjust the width as necessary */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #e8e8e8;
  margin: 0 10px; /* Add margin to create horizontal gaps */
  border-radius: 6px; /* Rounded corners for PDF sider */
  border: 1px solid #d9d9d9; /* Add border for better visibility */
  }



.pdf-sider {
  flex: 1 1 45vw; /* Adjust the width as necessary */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #e8e8e8;
  margin: 0 10px; /* Add margin to create horizontal gaps */
  border-radius: 6px; /* Rounded corners for PDF sider */
  border: 1px solid #d9d9d9; /* Add border for better visibility */
  
}


.pdf-content {
  flex: 1;
  overflow-y: auto;

}

.copy-button:hover {
  opacity: 1;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  border: none;
}

/* Responsive design */
@media (max-width: 1200px) {
  .content-container {
    flex-direction: column;
  }
 
  .chat-container,
  .context-sider,
  .pdf-sider {
    width: 100%;
    height: 33.33%;
    overflow-y: scroll;
  }

  .context-sider,
  .chat-container,
  .pdf-sider {
    border-left: none;
    border-top: 1px solid #e8e8e8;
  }
}
.search-radius-slider-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000; /* Ensure the button is above other content */
}
/* Add gap class for spacing */
.gap {
  height: 10px; /* Gap height for top and bottom spacing */
}
