.pdf-sider-card {
  height: 100vh; /* Ensure the container takes up the full height of the viewport */
  display: flex;
  flex-direction: column;
    position: relative; /* Ensure child elements are positioned relative to this container */
  }
  
.pdf-sider-controls {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.pdf-sider-zoom-slider {
  width: 100px;
  display: inline-block;
  margin-right: 10px;
}

.pdf-sider-container {
  flex: 1; /* Allow the container to grow and take up remaining space */
  overflow-y: auto;
  display: flex;
  justify-content: center;
  cursor: default;
  position: relative;
}

.pdf-sider-container.grab {
  cursor: grab;
}

.pdf-sider-document {
  transition: transform 0.3s ease-out;
}

.pdf-sider-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #f0f0f0;
  background: white; /* Ensure background color is set */
  position: sticky; /* Make sure pagination stays in place */
  bottom: 0; /* Stick to the bottom of the viewport */
  z-index: 1; /* Ensure it stays above the PDF content */
  margin-top: auto; /* Push pagination to the bottom when there's no content */
}
  .pdf-sider-blur {
    filter: blur(4px);
    pointer-events: none; /* Prevent interaction with blurred elements */
  }
  
  .pdf-sider-spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    z-index: 2; /* Ensure it stays above the content */
  }
  