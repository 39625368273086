/* reports.css */

.reports-container {
    display: flex;
  height: 86vh;
    background-color: #f0f2f5;
  border-radius: 8px;
  }
  
  .report-list,
  .report-details {
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 16px;
    border-radius: 8px;
    overflow-y: auto;
  }
  
  .report-list {
    flex: 1;
    padding: 20px;
  width:25vw;
  }
  
  .report-details {
    flex: 2;
    padding: 24px;
  border-radius: 8px;
  }
  
  .report-list-header {
    margin-bottom: 16px;
  }
  
  .report-item {
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 0;
  }
  
  .report-item:last-child {
    border-bottom: none;
  }
  
  .report-details-content {
    padding: 16px;
    background-color: #fafafa;
    border-radius: 4px;
  }
  
  .report-details-content h2 {
    margin-bottom: 16px;
  color: #000000;
}
.suneditor-container {
  height: 80%;
}

.sun-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sun-editor .se-wrapper-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sun-editor .se-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sun-editor .se-wrapper .se-wrapper-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.sun-editor .se-wrapper .se-wrapper-inner .se-wrapper-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  }
  
  .report-summary {
    margin-top: 20px;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .reports-container {
      flex-direction: column;
    }
  
    .report-list,
    .report-details {
      margin: 8px;
      max-height: 50vh;
    }
  }